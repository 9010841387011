<template>
  <div class="nav-tabs-wrapper">
    <ul v-if="!isWebUser" class="nav-tabs">
      <li
        v-for="tab in tabs"
        :key="tab.name"
      >
        <router-link
          :to="{name: tab.name}"
          class="nav-item"
        >
          {{ tab.title }}
          <span
            v-if="communications[tab.name] && communications[tab.name].count > 0"
            class="notifications-counter"
          >
            {{ communications[tab.name].count }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
  <div class="nav-list">
    <router-view name="navigation" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: "SectionTabs",
  data() {
    return {
      tabs: [
        {
          name: 'notifications',
          title: 'Уведомления',
        },
        {
          name: 'support',
          title: 'Поддержка',
        }
      ],
    }
  },
  computed: {
    ...mapState({
      communications: (state) => state.app.communications,
    }),
    ...mapGetters({
      isWebUser: "support/isWebUser"
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars";
@import "@/assets/styles/_mixins";

.nav-tabs-wrapper {
  margin: 0 0 18px;
}
.nav-tabs {
  display: flex;
  font-size: 16px;
  line-height: 22px;
  color: $rf-grey;
  margin: 0 0-$content-padding $content-padding;
  padding: 5px $content-padding 1px;
  border-bottom: 2px solid #eceff1;
  background: $rf-white;
  @include view-lg {
    margin: 0 0-$content-padding-lg $content-padding;
    padding: 0 $content-padding-lg;
  }
  > * {
    position: relative;
    + * {
      margin-left: $content-padding;
    }
  }
  .nav-item {
    padding: 4px 0 15px;
    margin-bottom: -2px;
    display: flex;
    position: relative;
    align-items: center;
    border-bottom: 2px solid transparent;
    @include view-lg {
      padding: 4px 0 10px;
    }
    &.router-link-active {
      border-bottom: 2px solid $rf-blue;
    }
    .notifications-counter {
      margin: 0 0 0 9px;
      display: inline-block;
      padding: 2px 6px;
      border-radius: 40px;
      background: #F44C4D;
      color: $rf-white;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0.25px;
    }
  }
  a {
    color: $rf-grey;
  }
}
.nav-list {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
</style>
